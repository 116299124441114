import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Logo from '../assets/images/logo.png'
import Title from './Title';
import CountdownTimer from'./CountdownTimer';
import InputArea from './InputArea';
import Button from './Button';
import { saveExpiry, saveQuestion, getQuestion } from '../helpers/question';
import { answerQuestion, askQuestion, fetchTeamList, signIn, fetchQuestionCount } from '../api';
import { getExpiry } from '../helpers/question';
import './QuestionPage.scss';

export default function QuestionPage({ws, role, token,  onAuth} : {token: string | null, ws?: WebSocket | undefined, role: string | null, onAuth: ({role, token} : {role: string, token: string}) => void}) {
  let [questionId, setQuestionId] = useState(getQuestion());
  let [login, setLogin] = useState('');
  let [password, setPassword] = useState('');
  let [questionText, setQuestionText] = useState('Добро пожаловать!');
  let [team, setTeam] = useState<string>('');
  let [teams, setTeams] = useState([])
  let [isQuestionActive, setQuestionActive] = useState<boolean>(false);
  let [questionCount, setQuestionCount] = useState<number | null>(null);

  const navigate = useNavigate();

  const auth = async () => {
    let { data } = await signIn(login, password);
    onAuth(data)
  }

  const updateLogin = (event : React.ChangeEvent<HTMLInputElement>) : void => {
    setLogin(event.target.value);
  }

  const updatePassword = (event : React.ChangeEvent<HTMLInputElement>) : void => {
    setPassword(event.target.value);
  }

  const onSubmit = async (text : string, team: string = '') => {
    if (role === 'jury') {
      try {
        await askQuestion(text, team);
        setQuestionCount((questionCount || 0) + 1)
        setQuestionActive(true);
      } catch(e) {
        console.log(e);
      }
    }
    if (role === 'user' && questionId) {
      let { status } = await answerQuestion(questionId, text);
      if (status === 200) {
        navigate('/team');
      }
    }
  }

  const onCountdownFinished = () => {
    if (role === 'user') navigate('/team');
    if (role === 'jury') getQuestionCount();
    setQuestionActive(false);
  }

  const getTeamList = async () => {
    let { data } = await fetchTeamList();
    setTeams(data);
  }

  const getQuestionCount = async () => {
    let { data } = await fetchQuestionCount();
    setQuestionCount(data.count);
  }

  useEffect(() => {
    if (role === 'checker') navigate('/questions');
    if (role === 'user' && getExpiry().getTime() < new Date().getTime()) navigate('/team');
    
    if (role === 'jury') getTeamList();
  }, [role, token]);

  useEffect(() => {
    if (role === 'jury' && getExpiry().getTime() > new Date().getTime()) setQuestionActive(true);
    if (role === 'jury') getQuestionCount();
  }, []);

  useEffect(() => {
    console.log('ws question page', ws);
    if (ws) {
      ws.onmessage = (event) => {
        let response = JSON.parse(event.data);
        console.log('onmessage', response);
        if(response.type === 'question') {
          setQuestionId(response.data.questionId);
          setQuestionText(response.data.text);
          saveQuestion(response.data.questionId);
          saveExpiry(response.data.deadline);
        }
      }
    }
  }, [ws]);

  if (!token) return (
    <div className="loginForm">
      <div className="loginForm-inputs">
        <input className="loginForm-input" value={login} onInput={updateLogin} type="text" placeholder="Login"></input>
        <input className="loginForm-input" value={password} onInput={updatePassword} type="password" placeholder="Password"></input>
      </div>
      <Button
        onClick={() => auth()}
        text='Войти'
      />
    </div>
  )

  if (role === 'screen') return (
    <div className="screen">
      <div className="screen-column">
        <img
          src={Logo}
          alt="logo"
          className="screen-logo"
        ></img>
      </div>
      <div className="screen-column">
        <CountdownTimer
          onCountdownFinished={onCountdownFinished}
        />
      </div>
      <div className="screen-question">
        {questionText}
      </div>
    </div>
  )

  return (
    <div className="main">
        <img
          src={Logo}
          alt="logo"
          className="main-logo"
        />
        <CountdownTimer
          onCountdownFinished={onCountdownFinished}
        />
        <InputArea
          onSubmit={onSubmit}
          buttonText={ role === 'user' ? 'Отправить' : 'Задать вопрос'}
          isJury={ role === 'jury' }
          questionCount={questionCount}
          teams={teams}
          team={team}
          setTeam={setTeam}
          isQuestionActive={isQuestionActive}
        />
        <Title/>
    </div>
  )
}
