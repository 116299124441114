import React, { useEffect, useState } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import AnswersPage from './Components/Judges/AnswersPage';
import QuestionsPage from './Components/Judges/QuestionsPage';
import QuestionPage from './Components/QuestionPage';
import TeamPage from './Components/TeamPage';
import './App.scss';
import RankingPage from './Components/RankingPage';

function App() {
  let [role, setRole] = useState(window.localStorage.getItem('role'));
  let [token, setToken] = useState(window.localStorage.getItem('token'));
  let [ws, setWebsocket] = useState<WebSocket>();

  const createWebsocket = () => {
    console.log('connecting wss...');
    let baseUrl = new URL(`${process.env.REACT_APP_BASE_URL}`);

    let newWs = new WebSocket(`wss://${baseUrl.host}?token=${token}`);
    newWs.onopen = () => {
      console.log('connected');
      setWebsocket(newWs);
    };
    let wsInterval = setInterval(() => {
      if (!newWs || newWs.readyState !== newWs.OPEN) {
        clearInterval(wsInterval);
        createWebsocket();
      }
    }, 5000);
  }

  const onAuth = async ({role, token} : {role: string, token: string}) => {
    setToken(token);
    setRole(role);
    window.localStorage.setItem('token', token);
    window.localStorage.setItem('role', role);
    createWebsocket();
  }

  useEffect(() => {
    if (!ws && token) {
      createWebsocket();
    }
  }, [token]);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={
            <QuestionPage 
              ws={ws}
              role={role}
              onAuth={onAuth}
              token={token}
            />
          }/>
          <Route path="/questions" element={
            (role === 'jury' || role === 'checker') ? <QuestionsPage role={role}/> : <Navigate to='/' replace/>
          }/>
          <Route path="ranking" element={
            (role === 'jury' || role === 'checker') ? <RankingPage/> : <Navigate to='/' replace/>
          }/>
          <Route path="/question/:questionId" element={
            (role === 'jury' || role === 'checker') ? <AnswersPage /> : <Navigate to='/' replace/>
          }/>
          <Route path="/team" element={
            (role === 'user') ? <TeamPage ws={ws}/> : <Navigate to='/' replace/>
          }>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
