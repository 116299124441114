import React, { useEffect, useState } from 'react';
import { useNavigate, useParams  } from "react-router-dom";
import { fetchAnswers, updateAnswerRating } from '../../api';

import Button from '../Button';
import Answers from './Answers';
import { QuestionByTeam } from './Answers';
import './AnswersPage.scss';

export default function AnswersPage() {
  const [questionData, setQuestionData] = useState<QuestionByTeam>({
    text: '',
    id: 0,
    answers: []
  });
  let { questionId } = useParams();
  let navigate = useNavigate();

  const updateData = async (answerId: string, isCorrect: boolean | string) => {
    await updateAnswerRating({answerId, isCorrect});
    
    let index = questionData.answers.findIndex(el => el.id === answerId);
    let newTeamAnswer = {
      ...questionData.answers[index],
      correct: isCorrect,
    }

    let newQD = {
      ...questionData,
      answers: [
        ...questionData.answers.slice(0, index),
        newTeamAnswer,
        ...questionData.answers.slice(index + 1)
      ]
    }

    setQuestionData(newQD);
  }

  useEffect(() => {
    const getAnswers = async () => {
      if (questionId) {
        let { data } = await fetchAnswers(questionId);
        setQuestionData(data);
      }
    }
    getAnswers();
  }, []);

  return (
    <div className="juryPage">
      <div className="juryPage-title">{questionData.id}. {questionData.text}</div>
      <Answers 
        questionData={questionData}
        updateData={updateData}
      />
      <Button
        onClick={() => navigate('/questions')}
        text="Назад"
      />
    </div>
  );
}
