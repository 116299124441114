import React, { useState, useEffect } from 'react';
import {  useNavigate } from "react-router-dom";

import TeamHeader from './TeamHeader';
import { saveExpiry, saveQuestion } from '../helpers/question';
import './TeamPage.scss';
import { Question } from './Answer';
import TeamAnswers from './TeamAnswers';
import { fetchTeamInfo } from '../api';

export default function TeamPage({ ws } : {ws?: WebSocket}) {
  const [teamInfo, setTeamInfo] = useState({
    name: '',
    questions: []
  });
  let navigate = useNavigate();

  if (ws) {
    ws.onmessage = (event) => {
      let response = JSON.parse(event.data);
      if(response.type === 'question') {
        saveQuestion(response.data.questionId);
        saveExpiry(response.data.deadline);
        navigate('/');
      }
      if(response.type === 'rating') {
        console.log(response.data);
        console.log(teamInfo);
        getQuestions();
      }
    }
  }

  const getPoints = (questions: Question []) => {
    let correctCount = 0;
    questions.forEach(question => {
      if (question.answer?.correct === 'true') correctCount++;
    });
    return correctCount;
  };

  const getQuestions = async () => {
    let { data } = await fetchTeamInfo();
    setTeamInfo(data);
  };

  useEffect(() => {
    getQuestions();
  }, []);

  return (
    <div className="team">
      <TeamHeader
        teamName={teamInfo.name}
        points={getPoints(teamInfo.questions)}
        totalQuestions={teamInfo.questions.length}
      />
      <TeamAnswers 
        questions={teamInfo.questions}
      />
    </div>
  );
}
